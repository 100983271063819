import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Box, useColorModeValue, Spinner, Center } from '@chakra-ui/react';
import { COLORS } from './colors';
import Landing from './pages/landing';
import Login from './pages/login';
import Create from './pages/create';
import Edit from './pages/edit';
import View from './pages/view';
import Dashboard from './pages/dashboard';
import Explore from './pages/explore';

const Loader = () => {
  return (
    <Center width="100%" height="100vh">
      <Box
        px={8}
        py={5}
        mx="auto"
        pb={20}
        backgroundColor={useColorModeValue(COLORS.white, COLORS.dark)}
      >
        <Spinner
          size="xl"
          color={useColorModeValue(COLORS.dark, COLORS.white)}
        />
      </Box>
    </Center>
  );
};
function App() {
  return (
    <Router>
      {/* <Suspense fallback={<Loader />}> */}
      <Routes>
        {/* <Route
            exact
            path="/room/:slug"
            render={props => <Room {...props} />}
          /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Landing />} />
        <Route path="/create" element={<Create />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/view" element={<View />} />
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/explore" element={<Explore/>}/>
      </Routes>
      {/* </Suspense> */}
    </Router>
  );
}

export default App;
