import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  useClipboard,
  Button,
  Center,
  chakra,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { COLORS } from '../colors';
import LogoBlack from '../assets/logo.svg';
import LogoWhite from '../assets/logoWhite.svg';
import { BiHomeAlt, BiMenu, BiPlus, BiSearch, BiLogOut } from 'react-icons/bi';
import { Link } from '@chakra-ui/layout';
import React from 'react';
import { BsGithub } from 'react-icons/bs';
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerCloseButton,
  DrawerHeader,
} from '@chakra-ui/react';


export default function WithSubnavigation({ screen }) {
  const { hasCopied, onCopy } = useClipboard(window.location.href);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>MENU</DrawerHeader>

          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Link
              href="/dashboard"
              style={{ textDecoration: 'none' }}
              p={5}
              display="flex"
              justifyItems="center"
              justifyContent="center"
            >
              <Flex alignItems="center">
                <BiHomeAlt size={30} mr={5} />
                <chakra.p ml={5}>Dashboard</chakra.p>
              </Flex>
            </Link>
            <hr />
            <Link
              href="/create"
              style={{ textDecoration: 'none' }}
              p={5}
              display="flex"
              justifyItems="center"
              justifyContent="center"
            >
              <Flex alignItems="center">
                <BiPlus size={30} />
                <chakra.h2 ml={5}>Create Roadmap</chakra.h2>
              </Flex>
            </Link>
            <hr />
            <Link
              href="/edit"
              style={{ textDecoration: 'none' }}
              p={5}
              display="flex"
              justifyItems="center"
              justifyContent="center"
            >
              <Flex alignItems="center">
                <BiSearch size={30} />
                <chakra.h2 ml={5}>Explore</chakra.h2>
              </Flex>
            </Link>
            <hr />
            <Link
              href="/view"
              style={{ textDecoration: 'none' }}
              p={5}
              display="flex"
              justifyItems="center"
              justifyContent="center"
            >
              <Flex alignItems="center">
                <BiLogOut size={30} />
                <chakra.h2 ml={5} color="red">
                  Logout
                </chakra.h2>
              </Flex>
            </Link>
            <hr />
          </DrawerBody>

          <DrawerFooter display="flex" justifyContent="center">
            <Link
              target="_blank"
              style={{ textDecoration: 'none' }}
              href="https://github.com/"
            >
              <BsGithub size="30px" />
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Flex
        bg={useColorModeValue(
          screen !== undefined ? COLORS.roomWhite : COLORS.white,
          COLORS.dark
        )}
        color={useColorModeValue('gray.600', 'white')}
        minH={'30px'}
        py={{ base: 2 }}
        px={{ base: 2 }}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <BiMenu
          onClick={onOpen}
          style={{ fontSize: '3rem', paddingTop: '5px', paddingRight: '16px' }}
        />
        <Flex flex={{ base: 1 }} justify={{ md: 'start' }}>
          <img
            src={useColorModeValue(LogoBlack, LogoWhite)}
            height="20"
            width="100"
            alt="logo"
          ></img>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={3}
        >
          
          <ColorModeSwitcher />
        <x
          style={{
            fontSize: '1.5rem',
            paddingBottom: '5px',
            paddingRight: '10px',
          }}
        >
          |
        </x>
        <img
          style={{ height: '30px', width: '30px', borderRadius: '50%' }}
          src="https://bit.ly/ryan-florence"
        />
        <h6 style={{ paddingLeft: '8px' }}>Joey T.</h6>
        </Stack>
      </Flex>
    </Box>
  );
}
