import { Box, Button, Text, Flex, Spacer } from '@chakra-ui/react';
import { useState } from 'react';
import Navbar from '../components/navbar-in';
import MDEditor from '@uiw/react-md-editor';

function Edit(props) {
  const [value, setValue] = useState(props.text);
  return (
    <>
      <Box position={'relative'} p={4} pb="0">
        <Navbar />
        <br />
      </Box>
      <hr />
      <Flex m="1" align="center">
        <Text as="samp" fontSize="lg" mt="0" pt="0" pl="5">
          Edit Roadmap ✏️
        </Text>
        <Spacer></Spacer>
        <Button variant="outline">Save</Button>
      </Flex>
      <MDEditor value={value} onChange={setValue} height="82vh" />

      {/* <MDEditor.Markdown source={value} /> */}

      {/* <Center>
        <Button colorScheme="teal" variant="outline">
          Save
        </Button>
      </Center> */}
    </>
  );
}

export default Edit;
