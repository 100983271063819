import {
  Box,
  Flex,
  HStack,
  VStack,
  chakra,
  useColorModeValue,
  Text,
  SimpleGrid,
  StackDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';
import React from 'react';
import { COLORS } from '../colors';
import Navbar from '../components/navbar-in';
import { MdDeleteOutline } from 'react-icons/md';
import { BiGitRepoForked, BiStar } from 'react-icons/bi';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
const features = Array.apply(null, Array(8)).map(function (x, i) {
  return {
    id: i,
    title: 'Web Designing',
    text: 'Prototyping',
    color: [
      'green.100',
      'purple.100',
      'red.100',
      'orange.100',
      'teal.100',
      'blue.100',
      'pink.100',
      'yellow.100',
    ][i],
    colbar: [
      'green',
      'purple',
      'red',
      'orange',
      'teal',
      'blue',
      'pink',
      'yellow',
    ][i],
    percent: ['45', '65', '30', '15', '50', '90', '45', '60'][i],
  };
});
const avatars = [
  {
    name: 'Ryan Florence',
    url: 'https://bit.ly/ryan-florence',
  },
  {
    name: 'Segun Adebayo',
    url: 'https://bit.ly/sage-adebayo',
  },
  {
    name: 'Kent Dodds',
    url: 'https://bit.ly/kent-c-dodds',
  },
  {
    name: 'Prosper Otemuyiwa',
    url: 'https://bit.ly/prosper-baba',
  },
  {
    name: 'Christian Nwamba',
    url: 'https://bit.ly/code-beast',
  },
];

const Dashboard = () => {
  const bg = useColorModeValue('white', 'gray.800');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      px={8}
      py={5}
      mx="auto"
      pb={20}
      backgroundColor={useColorModeValue(COLORS.white, COLORS.dark)}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure??</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              leftIcon={<MdDeleteOutline size={20} />}
              onClick={onClose}
              // color={useColorModeValue(COLORS.dark, 'white')}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Navbar />
      <Box
        p={4}
        marginTop="50px"
        maxW="1120px"
        mx="auto"
        bgColor={useColorModeValue('gray.200', 'gray.700')}
        borderRadius="30px"
      >
        <HStack>
          <Box pl={5} width={'100%'} h="auto">
            <Text fontSize={25} fontWeight={600}>
              Your Roadmaps
            </Text>
          </Box>
        </HStack>

        <SimpleGrid p={5} columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map(feature => (
            <VStack
              p={5}
              borderRadius={30}
              bgColor={feature.color}
              key={feature.id}
              align={'top'}
              spacing={3}
            >
              <HStack>
                <Box width="100%" h="auto">
                  <Text fontSize={'0.8rem'} color={'gray.600'} fontWeight={400}>
                    December, 10
                  </Text>
                </Box>
                <Menu>
                  <MenuButton>
                    <Box
                      width="100%"
                      h="auto"
                      align={'right'}
                      pb={'2'}
                      color="black"
                    >
                      <IoEllipsisVerticalSharp />
                    </Box>
                  </MenuButton>
                  <MenuList>
                    <Link href="/edit" style={{ textDecoration: 'none' }}>
                      <MenuItem>✏ Edit</MenuItem>
                    </Link>
                    <MenuItem onClick={onOpen}>❌ Delete</MenuItem>
                  </MenuList>
                </Menu>
              </HStack>

              <Box h="auto">
                <Text
                  display={'flex'}
                  justifyContent={'center'}
                  color={'gray.800'}
                  fontWeight={600}
                >
                  {feature.title}
                </Text>
                <Text
                  display={'flex'}
                  justifyContent={'center'}
                  color={'gray.600'}
                >
                  {feature.text}
                </Text>
              </Box>

              <Box h="auto">
                <Box h="auto" pb={'2'}>
                  <Text fontWeight={700} fontSize={'1.2rem'} color={'gray.800'}>
                    Progress
                  </Text>
                </Box>
                <Progress
                  hasStripe
                  colorScheme={feature.colbar}
                  isAnimated
                  size="sm"
                  value={feature.percent}
                  borderRadius={20}
                />
                <Box h="auto" align={'right'} pb={'2'}>
                  <Text fontWeight={700} fontSize={'1.2rem'} color={'gray.800'}>
                    {feature.percent}%
                  </Text>
                </Box>
                <StackDivider borderColor="gray.200" />
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  color="black"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <BiGitRepoForked size={18} />
                    <p>10</p>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    ml={3}
                  >
                    <BiStar size={18} />
                    <p>20</p>
                  </Flex>
                </Flex>
              </Box>
            </VStack>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Dashboard;
