import { Box, Flex, Text, Spacer, Button } from '@chakra-ui/react';
import { useState } from 'react';
import Navbar from '../components/navbar-in';
import MDEditor from '@uiw/react-md-editor';

function Create(props) {
  const [value, setValue] = useState(props.text);
  return (
    <>
      <Box position={'relative'} p={4} pb="0">
        <Navbar />
        <br />
      </Box>
      <hr />
      <Flex m="1" align="center">
        <Text as="samp" fontSize="lg" mt="0" pt="0" pl="5">
          Create Roadmap ✏️
        </Text>
        <Spacer></Spacer>
        <Button variant="outline">Create</Button>
      </Flex>
      <MDEditor value={value} onChange={setValue} height="82vh" />
    </>
  );
}

export default Create;
