import {
  Box,
  Heading,
  Container,
  Flex,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import { StarIcon, LinkIcon } from '@chakra-ui/icons';
import { BiGitRepoForked } from 'react-icons/bi';

import { useState } from 'react';
import Navbar from '../components/navbar-in';
import MDEditor from '@uiw/react-md-editor';

function View(props) {
  const [value, setValue] = useState('hello');
  return (
    <Box position={'relative'} p={6}>
      <Navbar />
      <br />
      <br />

      <Container
        maxW="container.xl"
        border="1px"
        borderColor="gray.200"
        borderRadius="20"
      >
        <br />
        <Flex>
          <Heading>Learn React</Heading>
          <Spacer />
          <IconButton
            mr={4}
            bg="teal"
            icon={
              <>
                <BiGitRepoForked boxSize="2rem" />
                <Box
                  as={'span'}
                  color={'white'}
                  position={'absolute'}
                  top={'1px'}
                  right={'1px'}
                  fontSize={'0.8rem'}
                  borderRadius={'llg'}
                  zIndex={2}
                  p={'1px'}
                >
                  5
                </Box>
              </>
            }
          ></IconButton>
          <IconButton
            mr={2}
            icon={
              <>
                <StarIcon />
                <Box
                  as={'span'}
                  color={'white'}
                  position={'absolute'}
                  top={'2px'}
                  right={'2px'}
                  fontSize={'0.8rem'}
                  borderRadius={'llg'}
                  zIndex={2}
                  p={'1px'}
                >
                  2
                </Box>
              </>
            }
          ></IconButton>
        </Flex>

        <br />
        <br />
        <MDEditor.Markdown source={value} />

        <br />
      </Container>
    </Box>
  );
}

export default View;
