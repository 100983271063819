import {
    Box,
    Flex,
    Stack,
    Heading,
    HStack,
    VStack,
    chakra,
    useColorModeValue,
    Text,
    Container,
    Input,
    show,
    InputRightElement,
    handleClick,
    
    SearchIcon,
    InputGroup,
    InputLeftElement,
    Button,
    PhoneIcon,
    SimpleGrid,
    StackDivider,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    Center,
    Icon,
    IconButton
  } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';  
  import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider,
  } from "@chakra-ui/react"
import { Progress } from "@chakra-ui/progress";
import React from 'react'
import { COLORS } from '../colors';
import Navbar from '../components/navbar-in';

import { BiGitRepoForked } from "react-icons/bi";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
const features = Array.apply(null, Array(8)).map(function (x, i) {
    return {
      id: i,
      title: 'Web Designing',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but a millinial.",
      
      percent: ['45', '65', '30', '15', '50', '90', '45', '60'][i],
      author: ['Joey T.', 'Chandler B.', 'Ross G.', 'Monika G.', 'Rachel G.', 'Pheobe B.', 'Ben G.', 'Emma G.'][i]
      
    };
  });
  
  
  const Explore = () => {
      const bg = useColorModeValue('white', 'gray.800');
      
      return (
          
        <Box
        px={8}
        py={5}
        mx="auto"
        pb={20}
        backgroundColor={useColorModeValue(COLORS.white, COLORS.dark)}
        >
            <Navbar/>
            <Box p={4} marginTop="50px" maxW="700px" mx="auto">
            <InputGroup >
              <InputLeftElement>
                    <FiSearch/>
              </InputLeftElement>
              <Input
                pr="4.5rem"
                type={ "text"}
                placeholder="Search"
              />
            </InputGroup>
            
            </Box>
               
            <Box  marginTop="50px" maxW="800px" mx="auto" borderRadius="30px" >
              <Text fontSize={30} fontWeight={700}>8 Search Results</Text>
            </Box>
            
            <Box pl={4} pr={4} pb={4} marginTop="50px" maxW="800px" mx="auto" borderRadius="30px" >

                
                
                
                
                    <SimpleGrid p={5} columns={{ base: 1, md: 2, lg: 1 }} spacing={10}>
                        
                    {features.map(feature => (
                        
                        
                        
                        <VStack
                            p={5} borderRadius={30} bgColor={bg=='white'?"gray.200":"gray.700"} key={feature.id} align={'top'}
                            spacing={3}
                            align="stretch"
                            >
                            
                                <HStack>
                                    <Box width='100%' h="auto" >
                                        <Text fontSize={'0.8rem'}  fontWeight={400}>December, 10</Text>
                                    </Box>
                                    <Menu>
                                    <MenuButton>
                                    <Box width='100%' h="auto"  align={'right'} pb={'2'}>
                                        <IoEllipsisVerticalSharp/>
                                    </Box>
                                    </MenuButton>
                                    <MenuList>
                                      <MenuItem>Star</MenuItem>
                                      <MenuItem>Edit</MenuItem>
                                      <MenuItem>Delete</MenuItem>
                                      
                                    </MenuList>
                                    </Menu>
                                </HStack>


                            
                            <Box h="auto">
                                
                                    <Text display={'flex'} pb="2" justifyContent={'center'}  fontWeight={600}>{feature.title}</Text>
                                    <Text display={'flex'} justifyContent={'center'} color={bg=='white'?"gray.500":"gray.400"}>{feature.text}</Text>
                                
                            </Box>
                            <HStack>
                              <Box h="auto" display="flex" pt="4" width="100%">
                                <img style={{height:"30px", width:"30px", borderRadius:"50%"}} src="https://bit.ly/ryan-florence"/>
                                <h6 style={{paddingLeft:"12px", paddingTop:"2px"}}>{feature.author}</h6>
                              </Box>
                              <Box width={'100%'} h="auto"  align={'right'}>
                                
                                <IconButton
                                  mr={4}
                                  icon={
                                    <>
                                      <BiGitRepoForked align={"right"} boxSize="2rem" />
                                      <Box
                                        as={'span'}
                                        position={'absolute'}
                                        top={'1px'}
                                        right={'1px'}
                                        fontSize={'0.8rem'}
                                        borderRadius={'llg'}
                                        zIndex={2}
                                        p={'1px'}
                                      >
                                        5
                                      </Box>
                                    </>
                                  }
                                ></IconButton>
                                <IconButton
                                  icon={
                                    <>
                                      <StarIcon align={"right"}/>
                                      <Box
                                        as={'span'}
                                        position={'absolute'}
                                        top={'2px'}
                                        right={'2px'}
                                        fontSize={'0.8rem'}
                                        borderRadius={'llg'}
                                        zIndex={2}
                                        p={'1px'}
                                      >
                                        2
                                      </Box>
                                    </>
                                  }
                                ></IconButton>
                            </Box>
                          </HStack>
                            
                        </VStack>
                        
                        
                    ))}
                    </SimpleGrid>
                
            </Box>
        </Box>
      )
  }
  
  export default Explore
  